import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { centerIdBuilder } from '@shared/idBuilder';
import {
  TCenter,
  TCustomMetric,
  buildCenterMock,
} from '@shared/interfaces/TCenter';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import { ROUTES_PATHS } from '../app-routing.module';
import {
  ConfirmDialogData,
  ConfirmationDialogComponent,
} from '../confirmation-dialog/confirmation-dialog.component';
import { TDataMaintenanceFooterButtonParams } from '../data-mainterance-fotter-button/data-maintenance-footer-button.component';
import {
  TCenterParams,
  TComponentParent,
  TComponentType,
} from '../interfaces/TComponentParams';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { TReportParams } from '../reports-review/reports-review.component';
import { CategoriesService } from '../services/CategoriesService';
import { CentersService } from '../services/CentersService';
import { DataProcessorService } from '../services/DataProcessorService';
import { LoadingService } from '../services/LoadingService';

export const NEW_CENTER_ID = `new_center`;

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss'],
})
export class CenterComponent implements OnInit, OnDestroy {
  public centerData: TCenter = {
    ...buildCenterMock({}),
  };
  public centerParams: TCenterParams = {
    __type: TComponentType.center,
    categoryId: ``,
    centerId: ``,
    projectId: ``,
  };
  public customMetricFormValid: boolean = true;
  public metrics: TCustomMetric[];

  private isNewFromOtherProject: boolean = false;
  private _subs: Subscription[] = [];

  constructor(
    public deleteConfirmationDialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _centersService: CentersService,
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService,
    private _dataProcessorService: DataProcessorService
  ) {}

  public footerButtonsData: TDataMaintenanceFooterButtonParams = {
    backCallback: () => this._backCallback(),
    backLabel: `Back`,
    deleteCallback: () => this._deleteCallback(),
    deleteLabel: `Delete`,
    saveCallback: () => this._saveCenterData(),
    saveLabel: `Save`,
    nextCallback: () => this._nextCallback(),
    nextLabel: `Next`,
    previousCallback: () => this._previousCallback(),
    previousLabel: `Previous`,
  };

  public ngOnInit(): void {
    this._subs.push(
      this._centersService.getCentersObservable().subscribe((centers) => {
        if (centers.length === 1) {
          const center: TCenter = centers.slice()[0];
          this.centerData = center;
        }
      })
    );
    this._subs.push(
      this._route.params.subscribe(async (params) => {
        this.centerParams = params as TCenterParams;
        this._setCenterInfo();
      })
    );
  }

  private _saveCenterData(): void {
    const isNew: boolean = this.centerParams.centerId === NEW_CENTER_ID;
    let newCenterId = ``;
    if (isNew) {
      newCenterId = `${centerIdBuilder(this.centerData.c_name)}-${newGuid()}`;
      this.centerData.c_id = newCenterId;
      this.centerData.projects = [this.centerParams.projectId];
    }
    const isDataValid: boolean =
      (!isNew || (isNew && this.centerData.c_name.length > 0)) &&
      this.customMetricFormValid;
    if (isDataValid) {
      const center = this.metrics.length
        ? { ...this.centerData, custom_metrics: this.metrics }
        : this.centerData;
      this._centersService.updateCenter(
        center,
        this.centerParams.projectId,
        () => {
          // this._setSalesData(this.project);
          if (isNew) {
            const centerParams: TCenterParams = {
              centerId: newCenterId,
              categoryId: this.centerParams.categoryId,
              projectId: this.centerParams.projectId,
              __type: TComponentType.center,
            };
            this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
              relativeTo: this._route,
            });
          }
          if (isNew || this.isNewFromOtherProject) {
            this._dataProcessorService.updateProjectCounts(
              this.centerParams.projectId
            );
          }
        }
      );
    } else {
      const errorMessage: string[] = [];
      if (this.centerData.c_id.length === 0) {
        errorMessage.push(`Center name`);
      }
      if (!this.customMetricFormValid) {
        errorMessage.push(`metrics`);
      }
      this._loadingService.triggerErrorMessage(
        `Please fill ${errorMessage.join(`, `)} field${
          errorMessage.length > 1 ? `s` : ``
        }`,
        4000
      );
    }
  }

  onCustomMetricFormValidityChange(isValid: boolean) {
    this.customMetricFormValid = isValid;
  }

  onCustomMetricFormValueChange(value: any) {
    this.metrics = value;
  }

  private _backCallback(): void {
    if (isNullOrUndefined(this.centerParams.projectId)) {
      this._router.navigate([`/${ROUTES_PATHS.dashboard}`]);
    } else if (this.centerParams.parent === TComponentParent.report) {
      const reportsParams: TReportParams = {
        data: ``,
        projectId: this.centerParams.projectId,
        loadFromCache: true,
      };
      this._router.navigate([`/${ROUTES_PATHS.report}`, reportsParams], {
        relativeTo: this._route,
      });
    } else {
      const projectReviewParams: TProjectReviewParams = {
        projectId: this.centerParams.projectId,
        categoryId: this.centerParams.categoryId,
        centersVisible: true,
        articlesVisible: false,
        hcpsVisible: false,
        settingsVisible: false,
      };
      this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
    }
  }

  private _deleteCallback(): void {
    const message = `Are you sure you want to delete ${this.centerData.c_name}?`;
    const dialogData: ConfirmDialogData = {
      title: `Delete Center`,
      message: message,
    };
    const dialogRef = this.deleteConfirmationDialog.open(
      ConfirmationDialogComponent,
      {
        maxWidth: `400px`,
        data: dialogData,
      }
    );

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this._centersService.deleteCenter(
          {
            categoryId: this.centerParams.categoryId,
            projectId: this.centerParams.projectId,
            ids: this.centerData.c_id,
          },
          () => {
            this._categoriesService.setCategories(() => {
              this._backCallback();
            });
          }
        );
      }
    });
  }

  private _setCenterInfo(): void {
    if (this.centerParams.centerId !== NEW_CENTER_ID) {
      this._centersService.setExtendedCenters(
        [this.centerParams.centerId],
        this.centerParams.categoryId,
        this.centerParams.projectId
      );
      if (
        !isNullOrEmpty(this.centerParams.centerId) &&
        isNullOrEmpty(this.centerParams.projectId)
      ) {
        this._loadingService.triggerInfoMessage(
          `Viewing Center outside of the project will not contains project specific data`,
          10000,
          `OK`
        );
      }
    }
  }

  private _nextCallback(): void {
    this._centersService.handleNextCenter(
      this.centerParams.centerId,
      this.centerParams.projectId,
      (centerId: string) => {
        const centerParams: TCenterParams = {
          centerId: centerId,
          categoryId: this.centerParams.categoryId,
          projectId: this.centerParams.projectId,
          __type: TComponentType.center,
          parent: this.centerParams.parent,
        };
        this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
          relativeTo: this._route,
        });
      }
    );
  }

  private _previousCallback(): void {
    this._centersService.handlePreviousCenter(
      this.centerParams.centerId,
      this.centerParams.projectId,
      (centerId: string) => {
        const centerParams: TCenterParams = {
          centerId: centerId,
          categoryId: this.centerParams.categoryId,
          projectId: this.centerParams.projectId,
          __type: TComponentType.center,
          parent: this.centerParams.parent,
        };
        this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
          relativeTo: this._route,
        });
      }
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._centersService.resetCenters();
    this.centerData = {
      ...buildCenterMock({}),
      projectsData: [],
    };
  }
}
